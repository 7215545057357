<template>
  <div class="flex_row exceptionWrapper">
    <div class="exceptionWrapper__img">
      <slot name="img"></slot>
    </div>
    <div class="exceptionWrapper__text">
      <h1><slot name="title"></slot></h1>
      <p><slot name="text"></slot></p>
      <div class="exceptionWrapper__text__btn">
        <!-- <router-link to="/"> -->
        <el-button type="primary" @click="goHome">返回首页</el-button>
        <!-- </router-link> -->
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'exceptionWrapper',
    methods: {
      goHome() {
        this.$router.push({
          path: this.$store.getters.homePath
        });
      }
    }
  };
</script>
<style lang="scss" scoped>
  @include b(exceptionWrapper) {
    justify-content: center;
    align-items: center;
    height: 100%;
    text-align: center;
    @include e(img) {
      display: inline-block;
      margin: 15px 30px;
      color: $color-text-regular;
      vertical-align: middle;
    }
    @include e(text) {
      display: inline-block;
      margin: 15px 30px;
      text-align: left;
      vertical-align: middle;
      h1 {
        margin: 0 0 20px;
        font-size: 72px;
        font-weight: 600;
      }
      p {
        margin: 0 0 25px;
        font-size: 20px;
      }
    }
  }
</style>
