<template>
  <exception-wrapper>
    <template slot="img">
      <img
        src="@/assets/images/svg/ic-500.svg"
        alt="500"
        width="400px"
        height="185px"
      />
    </template>
    <template slot="title">500 </template>
    <template slot="text">抱歉，服务器出错了 </template>
  </exception-wrapper>
</template>

<script>
  import exceptionWrapper from './exceptionWrapper.vue';

  export default {
    name: 'exception500',
    components: { exceptionWrapper }
  };
</script>
